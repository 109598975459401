.folder-container {
    display: flex;
    flex-wrap: wrap; /* Allow folders to wrap to new lines */
    padding: 10px;
    gap: 10px; /* Space between folders */
  }
  
  .folder {
    width: 100px; /* Set a fixed width for folders */
    height: 100px; /* Set a fixed height for folders */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px; /* Optional: rounded corners for the folder */
  }
  
  /* Specific folder styles */
  .folder.project-1 {
    position: absolute;
    top: 140px; /* Adjust as needed */
    left: 20px; /* Adjust as needed */
  }
  
  .folder.project-2 {
    position: absolute;
    top: 140px; /* Adjust as needed */
    left: 170px; /* Adjust as needed */
  }
  
  .folder.project-3 {
    position: absolute;
    top: 140px; /* Adjust as needed */
    left: 320px; /* Adjust as needed */
  }
  
  .folder.project-4 {
    position: absolute;
    top: 140px; /* Adjust as needed */
    left: 470px; /* Adjust as needed */
  }
  
  .folder.project-5 {
    position: absolute;
    top: 270px; /* Adjust as needed */
    left: 20px; /* Adjust as needed */
  }
  .folder.project-6 {
    position: absolute;
    top: 270px; /* Adjust as needed */
    left: 170px; /* Adjust as needed */
  }
  .folder .folder-icon {
    width: 50px;
    height: 50px;
    margin-bottom: 40px;
  }
  
  .folder .folder-label {
    font-size: 12px;
    text-align: center;
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
  