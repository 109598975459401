.folder-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px; /* Space above the folder */
  }
  
  .folder {
    position: relative;
    width: 100px; /* Adjust as needed */
    height: 100px; /* Adjust as needed */
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    overflow: hidden; /* Hide any overflow */
    top: -30px; /* Adjust top position */
    left: -250px;
  }
  
  .folder-icon {
    width: 55px; /* Adjust icon size */
    height: 55px; /* Adjust icon size */
    object-fit: contain;
  
  }
  
  .folder-label {
    position: absolute;
    bottom: 0; /* Adjust to place the label within the folder */
    text-align: center;
    font-size: 14px;
    color: #000; /* Text color */
    background: transparent; /* Ensure background is transparent */
    padding: 2px 5px; /* Padding if needed */
    white-space: nowrap; /* Prevent text from wrapping */
    width: 100%; /* Ensure the label spans the width of the container */
    font-family: 'Roboto', sans-serif;
  }