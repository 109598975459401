.folder-window {
    width: 700px;
    min-height: 500px;
    background-color: rgb(227, 227, 227); /* Light grey background for content */
    border: 3px solid #0057e7; /* Blue border */
    position: absolute;
    top: 10%;
    left: 30%;
    transform: translate(-50%, -50%);
    z-index: 10;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
    border-top-left-radius: 7px; /* Top left corner curve */
    border-top-right-radius: 7px; /* Top right corner curve */
    overflow: hidden;
}

.folder-window-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(0deg, rgb(0, 59, 214) 2%, rgb(0, 102, 253) 15%, rgb(0, 100, 253) 20%, rgb(0, 88, 230) 85%, rgb(54, 143, 252) 95%, rgb(13, 96, 232) 98%);
  padding: 8px;
  font-weight: bold;
  color: white;
  border-bottom: 2px solid #000080;
  font-family: 'Times New Roman', Times, serif;
  font-size: 18px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
  position: relative; /* Ensure positioning context for absolute children */
}

.minimize-button {
  background: linear-gradient(135deg, #2552bd 0%, #4483e7 100%); /* Gradient background */
  color: white;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid #005a9e;
  width: 25px;
  height: 25px;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 0px 1px white;
  position: absolute; /* Absolute positioning */
  right: 40px; /* Distance from the right edge of the header */
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Center vertically */
}

.close-button {
  background-color: rgb(238, 98, 71);
  color: white;
  font-size: 16px;
  font-weight: bold;
  border: none;
  width: 25px;
  height: 25px;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 0px 1px white;
  position: absolute;
  right: 10px; /* Distance from the right edge of the header */
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Center vertically */
}
.close-button:hover {
    background-color: rgb(255, 120, 100); /* Lighter red on hover */
}
  .folder-window-header span {
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8); /* Adds a black shadow around the text */
    font-family: 'Times New Roman', Times, serif; /* Ensures it's in Times New Roman */
    color: white; /* Makes the text white to contrast with the shadow */
  }
  
  .folder-window-header img {
    width: 100%;
    height: 100%;
    border-radius: 0; /* Remove any circular shape */
    object-fit: contain; /* Ensure the image fits within the button without distortion */
  }
.folder-window-header .folder-title {
    padding-left: 10px;
    font-family: 'Segoe UI', sans-serif;
}

.folder-window-content {
    padding: 20px;
    font-family: 'Courier New', Courier, monospace;
    color: #000;
    line-height: 1.6;
    max-height: 40vh;
    overflow-y: auto;
}



.minimize-button:hover {
  background-color: rgb(255, 255, 100); /* Lighter yellow on hover */
}




.folder-window-toolbar {
  background-color: rgb(237, 233, 212); /* Toolbar color */
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc; /* Light border to separate from the content */
  position: relative;
  margin-top: -2px; /* Pull toolbar up to overlap the blue outline */
}

.toolbar-buttons button {
  font-size: 12px;
  margin: 0 1px;
  border: none;
  background: none;
  cursor: pointer;
}


.toolbar-logo img {
  height: 20px; /* Adjust as needed */
}




.folder-window-navigation {
  padding: 0px;
  display: flex;
  align-items: center; /* Align items vertically center */
  border-bottom: 1px solid #ccc; /* Light border to separate from the content */
  background-color: rgb(237, 233, 212); /* Toolbar color */
}

.back-button,
.forward-button {
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 5px; /* Increased padding for larger height */
  position: relative;
  border-radius: 8px;
}

.back-button img,
.forward-button img {
  width: 27px; /* Adjust width as needed */
  height: 27px; /* Adjust height as needed */
}

.folder-window-navigation .navigation-buttons {
  display: flex;
  align-items: center; /* Align buttons vertically center */
}

.folder-window-navigation span {
  margin-left: 8px; /* Space between icon and text */
  font-size: 12px;
}

/* Ensure the navigation bar doesn’t exceed the width of the window */
.folder-window-navigation {
  flex: 1; /* Take up available space */
}
.back-button:hover,
.forward-button:hover {
  outline: 1px solid rgb(175, 175, 175); /* Creates the black box */
  outline-offset: -2px; /* Adjust the distance of the outline from the button */
}