.desktop-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    cursor: pointer;
    user-select: none; /* Prevents text selection */
    margin: 10px;
  }
  
  .desktop-icon img {
    width: 50px; /* Adjusted icon size */
    height: 50px; /* Keep the aspect ratio */
  }
  
  .desktop-icon span {
    margin-top: 5px;
    font-family: 'Tahoma', sans-serif;
    font-size: 12px;
    font-weight: bold; /* Matches Windows XP style */
    color: white;
    text-shadow: 1px 1px 0 #000000; /* Optional: Adds a slight shadow for better readability */
  }
  .desktop-icon:hover {
    background-color: rgba(0, 120, 215, 0.2); /* Light blue semi-transparent background on hover */
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3); /* Adds a soft shadow to create depth */
    border: 1px solid rgba(255, 255, 255, 0.5); /* Adds a subtle border to enhance the hover effect */
  }


  #sarthak-portfolio-icon {
    position: absolute;
    left: 30px;
    top: 30px; /* Position below "My Documents" */
  }

  #my-info-icon {
    position: absolute;
    left: 36px;
    top: 130px; /* Position below "My Documents" */
  }

  #sarthak-skill-icon {
    position: absolute;
    left: 38px;
    top: 235px; /* Position below "My Documents" */
  }
  #sarthak-project-icon {
    position: absolute;
    left: 160px;
    top: 130px; /* Position below "My Documents" */
  }

  /* Add IDs to the specific icons for precise positioning */
  #my-documents-icon {
    position: absolute;
    left: 150px; /* Same left position as "Sarthak's Portfolio" */
    top: 30px;  /* Adjust top to place it slightly above */
  }
  




  #sarthak-folder-icon img {
    width: 60px; /* Increase the width */
  height: 60px;
  }
  #sarthak-skill-icon img {
    width: 57px; /* Increase the width */
  height: 57px;
  }

  #my-info-icon img {
    width: 65px; /* Increase the width */
  height: 55px;
  }