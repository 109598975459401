.project2-content {
    padding: 20px;
    max-width: 1000px;
    margin: 0 auto;
  }
  
  .project2-photo-gallery {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 30px;
  }
  
  .project2-carousel-image {
    width: 100%;
    max-width: 800px;
    height: auto;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .carousel-button {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    font-size: 24px;
    padding: 10px 20px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
  
  .carousel-button.prev {
    left: 10px;
  }
  
  .carousel-button.next {
    right: 10px;
  }
  
  .project2-title {
    font-size: 28px;
    text-align: center;
    margin-bottom: 35px;
    margin-top: -15px;
  }
  
  .project2-description {
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .project2-links {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .project2-link {
    margin: 0 15px;
    text-decoration: none;
    color: #007bff;
    font-weight: bold;
  }
  
  .project2-section-title {
    font-size: 20px;
    margin-bottom: 15px;
    border-bottom: 2px solid #ddd;
    padding-bottom: 5px;
  }
  
  .project2-feature-list {
    list-style-type: disc;
    padding-left: 40px;
    margin-bottom: 30px;
  }
  
  .project2-roles {
    margin-bottom: 30px;
  }
  
  .project2-role-details p {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .project2-tech-stack {
    margin-bottom: 30px;
  }
  
  .project2-tech-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .project2-tech-item {
    display: flex;
    align-items: center;
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .project2-tech-icon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  