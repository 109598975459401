.project5-content {
    padding: 20px;
    max-width: 1000px;
    margin: 0 auto;
  }
  
  .project5-photo-gallery {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 30px;
  }
  
  .project5-carousel-image {
    width: 100%;
    max-width: 800px;
    height: auto;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .carousel-button.prev {
    position: absolute;
    left: 10px;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .carousel-button.next {
    position: absolute;
    right: 10px;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .project5-title {
    font-size: 28px;
    text-align: center;
    margin-bottom: 34px;
    margin-top: -15px;
  }
  
  .project5-description {
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .project5-links {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .project5-link {
    margin: 0 15px;
    text-decoration: none;
    color: #007bff;
    font-weight: bold;
  }
  
  .project5-section-title {
    font-size: 20px;
    margin-bottom: 15px;
    border-bottom: 2px solid #ddd;
    padding-bottom: 5px;
  }
  
  .project5-feature-list {
    list-style-type: disc;
    padding-left: 40px;
    margin-bottom: 30px;
  }
  
  .project5-roles {
    margin-bottom: 30px;
  }
  
  .project5-role-details p {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .project5-tech-stack {
    margin-bottom: 30px;
  }
  
  .project5-tech-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .project5-tech-item {
    display: flex;
    align-items: center;
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .project5-tech-icon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  
  /* Responsive Design */
  @media screen and (max-width: 768px) {
    .project5-carousel-image {
      width: 90%;
      max-width: 100%;
    }
  
    .carousel-button {
      font-size: 20px;
      padding: 8px 16px;
    }
  
    .project5-title {
      font-size: 24px;
    }
  
    .project5-description {
      font-size: 14px;
    }
  
    .project5-link {
      margin: 0 10px;
    }
  
    .project5-tech-item {
      padding: 8px;
    }
  
    .project5-tech-icon {
      width: 25px;
      height: 25px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .project5-carousel-image {
      width: 100%;
    }
  
    .carousel-button {
      font-size: 18px;
      padding: 6px 12px;
    }
  
    .project5-title {
      font-size: 20px;
    }
  
    .project5-description {
      font-size: 12px;
    }
  
    .project5-link {
      margin: 0 8px;
    }
  
    .project5-tech-item {
      padding: 6px;
    }
  
    .project5-tech-icon {
      width: 20px;
      height: 20px;
    }
  }
  