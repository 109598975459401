

/* Section styles */
.section-custom {
    margin-bottom: 80px;
}

.section-title-custom {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: left;
    position: relative;
    font-family: 'Arial', sans-serif;
}

.section-title-custom::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(to right, #3328c7 0%, transparent 100%);
    z-index: -1;
}

/* Folder containers */
.folder-container-custom {
    position: relative;
}

/* Manually positioned folder icons and labels */
.folder-icon-shared-docs-custom {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 10px;
    left: 1px;
}

.folder-label-shared-docs-custom {
    position: absolute;
    top: 24px;
    left: 65px;
    font-family: 'Arial', sans-serif;
    font-size: 13px;
}

.folder-icon-sarthak-docs-custom {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 10px;
    left: 250px;
}

.folder-label-sarthak-docs-custom {
    position: absolute;
    top: 24px;
    left: 305px;
    font-family: 'Arial', sans-serif;
    font-size: 13px;
}

.folder-icon-local-disk-custom {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 10px;
    left: 1px;
}

.folder-label-local-disk-custom {
    position: absolute;
    top: 22px;
    left: 65px;
    font-family: 'Arial', sans-serif;
    font-size: 13px;
}

.folder-icon-floppy-disk-custom {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 10px;
    left: 1px;
}

.folder-label-floppy-disk-custom {
    position: absolute;
    top: 22px;
    left: 65px;
    font-family: 'Arial', sans-serif;
    font-size: 13px;
}

.folder-icon-cd-drive-custom {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 10px;
    left: 250px;
}

.folder-label-cd-drive-custom {
    position: absolute;
    top: 24px;
    left: 305px;
    font-family: 'Arial', sans-serif;
    font-size: 13px;
}
