.project1-content {
    padding: 20px;
    max-width: 1000px;
    margin: 0 auto;
  }
  
  .project1-carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 30px;
  }
  
  .project1-carousel-image {
    width: 700px; /* Fixed width */
    height: 320px; /* Fixed height */
    object-fit: contain; /* Maintain aspect ratio and fit within the container */
    border-radius: 10px;
  }

  
  .carousel-button.prev {
    left: 10px;
  }
  
  .carousel-button.next {
    right: 10px;
  }
  
  .project1-title {
    font-size: 37px;
    text-align: center;
    margin-bottom: 35px;
    margin-top: -15px;
    /* font-family:Arial, sans-serif; */

  }
  
  .project1-description {
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .project1-links {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .project1-link {
    margin: 0 15px;
    text-decoration: none;
    color: #007bff;
    font-weight: bold;
  }
  
  .project1-section-title {
    font-size: 20px;
    margin-bottom: 15px;
    border-bottom: 2px solid #ddd;
    padding-bottom: 5px;
  }
  
  .project1-features {
    margin-bottom: 30px;
  }
  
  .project1-feature-list {
    list-style-type: disc;
    padding-left: 40px;
  }
  
  .project1-roles {
    margin-bottom: 30px;
  }
  
  .project1-role-details p {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .project1-tech-stack {
    margin-bottom: 30px;
  }
  
  .project1-tech-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .project1-tech-item {
    display: flex;
    align-items: center;
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .project1-tech-icon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  
  /* Responsive Design */
  @media screen and (max-width: 768px) {
    .project1-carousel-image {
      width: 100%; /* Use 100% of the available width on smaller screens */
      height: auto; /* Automatically scale height */
    }
  
    .carousel-button {
      font-size: 20px;
      padding: 8px 16px;
    }
  
    .project1-title {
      font-size: 24px;
    }
  
    .project1-description {
      font-size: 14px;
    }
  
    .project1-link {
      margin: 0 10px;
    }
  
    .project1-tech-item {
      padding: 8px;
    }
  
    .project1-tech-icon {
      width: 25px;
      height: 25px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .project1-carousel-image {
      width: 100%; /* Full width on very small screens */
    }
  
    .carousel-button {
      font-size: 18px;
      padding: 6px 12px;
    }
  
    .project1-title {
      font-size: 20px;
    }
  
    .project1-description {
      font-size: 12px;
    }
  
    .project1-link {
      margin: 0 8px;
    }
  
    .project1-tech-item {
      padding: 6px;
    }
  
    .project1-tech-icon {
      width: 20px;
      height: 20px;
    }
  }
  