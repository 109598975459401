/* SkillsContent.css */
.skills-card {
    background-color: #c1bfbf; /* Light grey background */
    padding: 20px;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 
    4px 4px 8px rgba(0, 0, 0, 0.3), /* Right and bottom shadow */
    -2px -2px 4px rgba(255, 255, 255, 0.5) inset; 
    width: calc(100% - 60px); /* Leave some space around the card */
    margin-bottom: 30px;
}

.skills-title {
    font-family: Arial; /* Custom font for the title */
    font-size: 28px;
    color: #000000; /* Green color */
    margin-bottom: 20px;
    margin-top: 5px;
    text-align: center; /* Center-align the title */
}


.skills-divider {
    border: none;
    height: 2px; /* Adjust height to increase line thickness */
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, #000000 50%, rgba(0, 0, 0, 0) 100%);
    margin: 10px 0; /* Margin for spacing around the line */
    width: 90%; /* Increase width of the line */
    margin-left: auto; /* Center-align horizontally */
    margin-right: auto; /* Center-align horizontally */
  }
  

.skills-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    overflow-y: auto; /* Enable vertical scrolling */
    max-height: 70vh; /* Set a max height to allow for scrolling if content overflows */
    padding-right: 10px; /* Add padding to avoid cutting off the scrollbar */
    justify-content: center;
}

.skill-item {
    font-family: 'Arial', sans-serif; /* Font for skills */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    font-size: 14px;
    color: white;
    padding: 10px 20px;
    border-radius: 20px;
    display: inline-flex;
    align-items: center;
    margin: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    font-weight: bold; /* Bold text */
    text-transform: uppercase; /* All caps */
    letter-spacing: 1px; /* Space between letters */
    background-color: #333; /* Background color for the skill item */
}
.skill-item:hover {
    transform: scale(1.1); /* Enlarge the item by 10% */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: Add shadow on hover */
}
.skill-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px; /* Space between icon and text */
}

/* New entries added */
.skill-item-react {
    background-color: #20232A; /* React color */
}

.skill-item-javascript {
    background-color: #e19a29; /* JavaScript color */
}

.skill-item-java {
    background-color: #474498; /* Java color */
}

.skill-item-python {
    background-color: #3475a9; /* Python color */
}

.skill-item-c\# {
    background-color: #6C3483; /* C# color */
}

.skill-item-express {
    background-color: #d59e8b; /* Express color */
}

.skill-item-node\.js {
    background-color: #4b771d; /* Node.js color */
}

.skill-item-c\+\+ {
    background-color: #185c83; /* C++ color */
}

.skill-item-html5 {
    background-color: #E44D26; /* HTML5 color */
}

.skill-item-css3 {
    background-color: #264DE4; /* CSS3 color */
}

.skill-item-aws {
    background-color: #232F3E; /* AWS color */
}

.skill-item-typescript {
    background-color: #2F74C0; /* TypeScript color */
}

.skill-item-unity {
    background-color: #525e51; /* Unity color */
}

.skill-item-tensorflow {
    background-color: #ba5000; /* TensorFlow color */
}

.skill-item-pytorch {
    background-color: #ae42c1; /* PyTorch color */
}

.skill-item-scikit-learn {
    background-color: #533fc0; /* scikit-learn color */
}

.skill-item-huggingface {
    background-color: #d2bc4b; /* Hugging Face color */
}

.skill-item-next\.js {
    background-color: #000000; /* Next.js color */
}

.skill-item-figma {
    background-color: #dd9521; /* Figma color */
}

.skill-item-webpack {
    background-color: #3d8e98; /* Webpack color */
}

.skill-item-vue\.js {
    background-color: #3e7373; /* Vue.js color */
}

.skill-item-redux {
    background-color: #9a93c0; /* Redux color */
}

.skill-item-docker {
    background-color: #2b5677; /* Docker color */
}

.skill-item-postgressql {
    background-color: #36398a; /* PostgreSQL color */
}

.skill-item-material-ui {
    background-color: #0e151c; /* Material-UI color */
}

.skill-item-mongodb {
    background-color: #4e7248; /* MongoDB color */
}

.skill-item-git {
    background-color: #7d3f34; /* Git color */
}

.skill-item-mysql {
    background-color: #222d68; /* MySQL color */
}
