.taskbar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  background-color: rgb(0, 78, 163);
  display: flex;
  align-items: center;
  /* Keep Start button and items aligned to the left */
  box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.2);
  z-index: 10;
}
@keyframes slideUpFromTaskbar {
  0% {
    transform: translateY(200%);
  }
  100% {
    transform: translateY(0);
  }
}

.start-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 100%;
  background: linear-gradient(to bottom, #76A753, #4CAF50);
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  border: 1px solid #3E7E28;
  cursor: pointer;
  box-shadow: inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(0, 0, 0, 0.3);
}

.start-button:hover {
  background-color: #4C9A2E; /* Lighter red on hover */
}

.start-button img {
  height: 60%;
  margin-right: 6px;
}

.start-button span {
  font-family: 'Tahoma', sans-serif;
  font-size: 18px;
  color: white;
  font-weight: bold;
  font-style: italic;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}
.start-menu-header .profile-pic {
  width: 73px;
  height: 73px;
  border-radius: 8px;
  border: 2px solid white;
  margin-right: 10px;
}

.start-menu-header .profile-name {
  font-size: 18px;
  font-weight: bold;
  margin-left: 5px; /* Add some space to the left */
  margin-top: -22px; 
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
}

/* .clock-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100%;
  background-color: rgb(66, 160, 233);
  border: 1px solid #3E7E28;
  color: white;
  font-family: 'Tahoma', sans-serif;
  font-size: 13.5px;
  text-align: center;
} */

.clock-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100%;
  background-color: rgb(66, 160, 233);
  border: 1px solid #3E7E28;
  color: white;
  font-family: 'Tahoma', sans-serif;
  font-size: 13.5px;
  text-align: center;
  margin-left: auto; /* Pushes the clock to the right */
}
.start-menu-header {
  position: relative; /* Make it the reference point for the pseudo-element */
  display: flex;
  align-items: center;
  padding: 10px;
  background: linear-gradient(to bottom, rgb(26, 106, 203)0%, #6CA9E3 90%); 
  color: white;
  height: 80px;
  border-radius: 0 15px 0 0; /* Only top-right corner is curved */
}

.start-menu-header::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3.5px; /* Thickness of the line */
  background: linear-gradient(to right, rgba(230, 170, 60, 0) 0%, rgb(230, 170, 60) 50%, rgba(230, 170, 60, 0) 100%);
  /* This creates a gradient that fades at both ends */
}

.start-menu {
  position: absolute;
  bottom: 40px; /* Position above taskbar */
  left: 0;
  width: 420px;  /* Fixed width to match the Windows XP style */
  height: 490px; /* Fixed height to match proportions */
  background-color: rgb(66, 160, 233); /* Background color */
  border: 1px solid #3E7E28;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensure the footer stays at the bottom */
  animation: slideUpFromTaskbar 0.4s ease-out;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  z-index: 11;
  font-family: 'Tahoma', sans-serif;
  color: black;
  border-radius: 0 15px 0 0; /* Apply the curve to the top-right of the entire menu */
}

.start-menu-content {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.start-menu-content-left {
  width: 50%;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.start-menu-content-right {
  width: 50%;
  background-color: rgb(221, 235, 250); /* Light blue background */
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.vertical-separator {
  width: 1.2px;
  background-color: #b7c5d8dc;
}

.start-menu-item {
  display: flex;
  align-items: center;
  padding: 8px 0;
  font-size: 12px;
  cursor: pointer;
  color: black;
}

.start-menu-item img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.start-menu-item-right {
  display: flex;
  align-items: center; /* Ensures the text and image are vertically centered */
  padding: 0 0;
  font-size: 12px;
  cursor: pointer;
  color: black;
}

.start-menu-item-right img {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  margin-bottom: 1px; /* Remove margin-bottom so the image is vertically aligned with the text */
}

.menu-item-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns the text to the start (left) */
  margin-left: 1px; 
}
.menu-item-text-right {
  display: flex;
  font-size: 11px;
  color: #27579c;
  flex-direction: column;
  align-items: center; /* Aligns the text to the start (left) */
  margin-left: 1px; 
  align-items: flex-start;
  
}
.menu-item-text-right-nonbold {
  display: flex;
  font-size: 11px;
  color: #27579c;
  flex-direction: row;
  align-items: center; /* Aligns the text to the start (left) */
  margin-left: 1px; 
}


.menu-item-text strong {
  font-size: 10px;
  
}

.menu-item-text span {
  font-size: 12px;
  color: grey;
}

.start-menu-item:hover {
  background-color: #e5e5e5;
}
.start-menu-item-right:hover {
  background-color: #9cccea;
}


.all-programs-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding:0;
  background-color: white;
  color: black;
  font-weight: bold;
  font-size: 11px;
  cursor: pointer;
  margin-top: 3px;
}

.all-programs-button img {
  width: 17px;
  height: 17px;
  margin-right: 12px;
}

.all-programs-separator {
  width: 100%;
  height: 1.2px;
  background: linear-gradient(to right, rgba(211, 211, 211, 0) 0%, lightgray 50%, rgba(211, 211, 211, 0) 100%);
  margin: 8px 0;
  margin-top: 40px; /* Moves the line down */
  margin-bottom: 0; 
}
.all-programs-button span {
  padding-left: 8px; /* Add padding to the left of the text */
  text-align: left;
}

.right-column-separator {
  width: 100%;
  height: 1.5px;
  background: linear-gradient(to right, rgba(211, 211, 211, 0) 0%, rgb(103, 161, 206) 50%, rgba(211, 211, 211, 0) 100%);
  margin: 10px 0; /* Vertical space around the separator */
}







.start-menu-footer {
  height: 50px;
  background: linear-gradient(to top, rgb(26, 106, 203) 0%, #6CA9E3 90%);
  border-radius: 0 0 0 0; /* Only bottom-right corner is curved */
  display: flex;
  justify-content: space-around; /* Space between items in footer */
  align-items: center;
  padding: 0 10px;
}

.footer-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: white;
  font-family: 'Tahoma', sans-serif;
  font-size: 13px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  margin-right: -300px;

}

.footer-item img {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}


.taskbar-item {
  background: linear-gradient(0deg, rgb(0, 59, 214) 2%, rgb(0, 102, 253) 15%, rgb(0, 100, 253) 20%, rgb(0, 88, 230) 85%, rgb(54, 143, 252) 95%, rgb(13, 96, 232) 98%);
  color: white;
  font-size: 14px;
  width: 200px; /* Fixed width */
  height: 37px;
  padding: 5px;
  margin-right: 0px; /* Reduced margin for closer alignment */
  border: 1px solid #0b3b8a; /* Darker border to mimic XP style */
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align items to the start */
  cursor: pointer;
  box-sizing: border-box; /* Ensure padding and border are included in width */
}

.taskbar-item-icon {
  width: 16px; /* Adjust size as needed */
  height: 16px;
  margin-right: 5px; /* Space between icon and text */
}


.taskbar-item:hover {
  background-color: #2a72d8; /* Slightly lighter blue on hover */
}

.taskbar-item img {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}