.my-info-divider {
    border: none;
    height: 2px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, #000000 50%, rgba(0, 0, 0, 0) 100%);
    margin: 20px 0; /* Margin for spacing around the line */
    width: 80%; /* Adjust width as needed */
    margin-left: auto;
    margin-right: auto;
}

.my-info-text {
    font-size: 16px;
    color: #000000;
    text-align: center; /* Center-align text */
    margin-top: 10px;
}
.my-info-experiences {
    background-color: #d5d2d2;
    padding: 15px;
    margin-top: 20px;
    border-radius: 10px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3), -2px -2px 4px rgba(255, 255, 255, 0.5) inset;
    overflow: hidden;
    
}

.my-info-experiences-title {
    font-family: Arial, sans-serif;
    font-size: 20px;
    color: #000000;
    margin-bottom: 10px;
    margin-top: 5px;
}
.my-info-picture {
    width: 100px; /* Adjust as needed */
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    display: block;
    margin: 0 auto; /* Center-align the picture */
}
.my-info-content {
  padding: 20px; /* Add padding for spacing */
  overflow: auto; /* Ensure scrolling if needed */
  max-height: calc(100vh - 120px); /* Adjust based on your layout */
  position: relative; /* Ensure correct positioning for child elements */
}

.my-info-text {
  font-size: 16px;
  color: #000000;
  text-align: center; /* Center-align text */
  margin-top: 10px;
  position: relative; /* Ensure text stays in place */
  z-index: 2; /* Ensure text is on top of background */
}

.my-info-experiences {
  background-color: #d5d2d2;
  padding: 15px;
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3), -2px -2px 4px rgba(255, 255, 255, 0.5) inset;
  overflow: hidden;
  position: relative; /* Ensure container is positioned correctly */
  z-index: 1; /* Place it above the animated elements */
}

.sphere-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; /* Adjust as needed */
  position: relative; /* Relative positioning for animated elements */
  gap: 130px;
  flex-wrap: wrap;
  margin-top: 20px; /* Adjust as needed */
  margin-bottom: 15px;
  z-index: 0; /* Ensure it’s behind the text */
}

.sphere {
  width: 120px;
  height: 120px;
  background:
  radial-gradient(40px at 30% 30%, rgba(180, 140, 100, 0.15) 0%, rgba(180, 140, 100, 0.15) 99%, transparent 100%),
  radial-gradient(40px at 25% 20%, rgba(180, 140, 100, 0.5) 0, rgba(235, 210, 170, 0.3) 8%, transparent 40%, transparent 100%),
  radial-gradient(100px at 25% 20%, rgba(160, 100, 50, 0.4) 0%, transparent 100%),
  radial-gradient(100px at 80% 90%, rgba(120, 60, 10, 0.6) 0%, transparent 100%);
  border-radius: 50%;
  position: relative;
  animation: hover 2s ease 0s infinite;
}

.sphere:after {
  content: '';
  position: absolute;
  left: 25%;
  top: 50%;
  z-index: -1;
  box-shadow: 0 100px 20px rgba(51, 51, 51, 0.5);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  transform: rotateX(70deg);
  animation: hover-shadow 2s ease 0s infinite;
}

.ball-logo {
  position: absolute; /* Position logo inside the sphere */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.8); /* Center and scale down */
  width: 110px; /* Adjust width as needed */
  height: 45px;
}

.ball-logo-2 {
  position: absolute; /* Position logo inside the sphere */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.8); /* Center and scale down */
  width: 150px; /* Adjust width as needed */
  height: 85px;
}

@keyframes hover {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, -10px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

@keyframes hover-shadow {
  0% {
    box-shadow: 0 100px 20px rgba(51, 51, 51, 0.5);
  }
  50% {
    box-shadow: 0 120px 40px rgba(51, 51, 51, 0.5);
  }
  100% {
    box-shadow: 0 100px 20px rgba(51, 51, 51, 0.5);
  }
}


.info {
  text-align: center;
  margin-top: 10px;
}

.position, .company, .date {
  margin: 0;
  padding: 0;
}

.position {
  font-weight: bold;
  font-family: 'Arial', sans-serif; /* Change to desired font family */

  font-size: 17px; /* Adjusted font size */
}

.company {
  font-family: 'Arial', sans-serif; /* Change to desired font family */

  font-size: 12px; /* Adjusted font size */
}

.date {
  color: #555555;
  font-size: 10px; /* Adjusted font size */
}
.experience-text {
  position: absolute;
  text-align: center;
  z-index: 2;
}

.experience-1 {
  top: 78%; /* Adjust as needed */
  left: 22%; /* Adjust as needed */
}

.experience-2 {
  top: 78%; /* Adjust as needed */
  right: 22%; /* Adjust as needed */
}