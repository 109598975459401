body {
    background-color: rgb(87, 125, 216);
    height: 100vh;
    margin: 0;
    font-family: 'Tahoma', sans-serif;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-container {
    display: flex;
    flex-direction: column;  
    align-items: flex-end;  
    justify-content: center;  
    position: absolute;
    top: 50%;
    left: 35%;
    transform: translate(-50%, -50%);
}
.logo-container img {
    width: 300vw ;
    min-width: 100px ;
    max-width: 250px ;
}

.login-container, .logo-container {
   overflow: visible; 
}


.welcome-text {
    font-size: 30px; 
    position: relative;
    right:48px;  
}

.center-line {
    position: absolute;
    top: 15%; 
    left: 50%;
    transform: translateX(-50%);
    height: 70%; 
    width: 2px;
    background: linear-gradient(to top, transparent, rgba(255, 255, 255, 0.5), transparent);
    z-index: 2;
}

.top-line, .bottom-line {
    position: absolute;
    left: 0; 
    width: 100%; 
    height: 4px;
    background: linear-gradient(to right, transparent, white, transparent);
    z-index: 2; 
}

.bottom-line {
    background: linear-gradient(to right, transparent, rgb(230, 170, 60), transparent); 
}

.top-line {
    top: 13.4%; 
}

.bottom-line {
    bottom: 13.4%;
}

.top-background {
    position: absolute;
    top: 0;
    left: 0; 
    width: 100%; 
    height: 13.4%; 
    background-color: rgb(1, 48, 154); 
    z-index: 1;
}

.bottom-background {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; 
    height: 13.4%; 
    background-color: rgb(1, 48, 154); 
    z-index: 1;
}

.user-section {
    position: absolute;
    top: 50%;
    left:57%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.user-icon img {
    width: 9vw; 
    height: 9vw;
    max-width: 76px; 
    max-height: 76px; 
    border: 3px solid white;
    border-radius: 10px;
}

.user-name {
    position: absolute; 
    top: 10%; 
    left: 250%; 
    transform: translate(-50%, -50%); 
    font-size: 27px; 
    color: white;
    margin-top: 9px; 
    font-weight: normal;
    text-align: center;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4); 
    pointer-events: none; 
    transition: color 0.3s ease; 
    font-weight: normal;
    font-family: 'Tahoma', sans-serif;
    white-space: nowrap;
    width: max-content;
}

.user-icon:hover img {
    filter: brightness(1);
    transition: filter 0.3s ease;
    backface-visibility: hidden; 
    transform: translateZ(0); 
    border: 3px solid rgb(230, 170, 60);
}

.user-icon:hover {
    filter: brightness(1.2); 
}

.user-icon:hover .user-name {
    color: #ececec; 
    transition: color 0.3s ease;
}

.login-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 30%;
    height: 50%; 
    background: radial-gradient(circle at top left, rgba(255, 255, 255, 0.5), transparent 70%);
    pointer-events: none;
    z-index: 1; 
}

.user-icon:active img {
    box-shadow: 0px 0px 25px 10px rgba(0, 0, 0, 0.5); 
}

.turn-off-container {
    position: absolute;
    top: 89%; 
    left: 3%; 
    display: flex;
    align-items: center;
    cursor: pointer;
    z-index: 3;
    padding: 10px; 
    border-radius: 10px;
    transition: background-color 0.3s ease;
}
.turn-off-container img {
    margin-right: 10px;
    border: 2px solid white;
    border-radius: 8px;
    width: 30px;
    transition: transform 0.3s ease, border-color 0.3s ease;
}
.turn-off-container span {
    font-size: 28px;
    color: white;
    transition: color 0.3s ease;
    white-space: nowrap; 
}
.welcome-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-family: 'Tahoma', sans-serif;
    background-color: rgb(87, 125, 216);
    color: white;
  }
  
  .welcome-screen h1 {
    font-size: 4vw;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4); 
    font-family: 'Tahoma', sans-serif;
    font-style: italic;
    text-align: flex-end;
    display: flex;
    flex-direction: column;  
    justify-content: center;  
    position: absolute;
    top: 45%;
    left: 39%;
    transform: translate(-50%, -50%);
  }

  .no-hover .user-icon img {
    filter: none !important;
    border: 3px solid white !important;
    transition: none !important;
}

.no-hover .user-name {
    color: white !important;
    transition: none !important;
}

/* ----------------------------------------------------------------------------------------------------------------------- */




