
  .wallpaper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-image: url('../../public/bliss.jpg'); /* Ensure the path is correct */
    background-size: cover; /* Makes the image cover the entire screen */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    background-attachment: fixed; /* Keeps the image fixed in place */
    background-position: center; /* Centers the image */
    z-index: -1; /* Ensures the wallpaper is behind all other elements */
  }
  